<template>
  <div class="header flexCenter-ai-center flexCenter-jc-sb">
    <div class="logo flexCenter-ai-center"  @click="nextPage('', 0)">
      <img src="@/assets/images/logo.png" alt="">
      <h1 class="title">
        {{$t('message.app_name')}}
      </h1>
    </div>

    <div class="user_data flexCenter-ai-center">
      <div class="information" @click="business">
        <img src="@/assets/images/information.png" alt="">
        <span>{{$t('message.contact')}}</span>
      </div>
      <div class="user" @click="nextPage('user', 0)">
        <img src="@/assets/images/user.png" alt="">
        <span>{{$store.state.userInfo.distributor_name}}</span>
      </div>
      <div class="exit" @click="nextPage('login', 1)">
        <img src="@/assets/images/exit.png" alt="">
        <span>{{$t('message.exit')}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'headers',
  data () {
    return {

    }
  },
  methods: {
    nextPage(path, type) {
      if  (type) {
        localStorage.removeItem('user_data')
        this.$store.commit('setUserInfo', {})
        this.$router.push(
          {
            path
          }
        )

      } else if (this.$store.state.userInfo.sign_status != 1) {
        let url = '/#/' + path
        window.open(url)
      }
    },
    business() {
      this.$emit('business')
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  height: 100%;
  background: #3D76FF;
  box-shadow: 0px 6px 10px 0px rgba(156, 159, 167, 0.08);
  padding-left: 42px;
  padding-right: 50px;
  .logo {
    cursor: pointer;
    img {
      width: 36px;
      height: 36px;
      // width: 138px;
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      color: #FFFFFF;
      margin-left: 4px;
      margin-top: -2px;
    }
  }
  .user_data {
    >div {
      margin-left: 40px;
      cursor:pointer;
      opacity: 0.8;
      img {
        width: 22px;
        margin-right: 4px;
      }
      span {
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
    }
    >div:nth-child(1) {
      margin-left: 0;
    }
    >div:hover {
      opacity: 1;
    }
  }
}
</style>

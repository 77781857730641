<!--
 * @Author: zhangtao
 * @Date: 2020-07-28 10:07:38
 * @LastEditTime: 2020-09-18 11:09:45
 * @Description: file content
--> 
<template>
  <div>
    <!-- 错误警示 -->
    <div class="warning"
         v-if="type=='warn'">
      <div class="content">
        <span class="el-alert__title">
          {{errorText}}
        </span>
        <el-button class="btn"
                   @click="closeBox">{{$t('message.ok')}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errorText: String,
    type: String,
  },
  data () {
    return {
      chapter_time: Date.now(),
      needChapter_time: false,
      chapter_active: 0
    }
  },
  methods: {
    // 取消
    closeBox () {
      this.$emit('setType', '')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.warning {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  font-size: 18px;
  z-index: 111;
  text-align: center;
  .content {
    max-width: 440px;
    margin: 0px auto;
    width: 100%;
    @include center();
    background: rgb(255, 255, 255);
    border-radius: 10px;
    overflow: hidden;
    padding: 40px;
    text-align: center;
  }
  .el-alert__title {
    background: rgb(255, 255, 255);
    overflow: hidden;
    padding: 15px 8px 40px;
    text-align: center;
    display: block;
    font-size: 18px;
  }
  .btn {
    background: $color;
    color: rgb(255, 255, 255);
    padding: 17px;
    text-align: center;
    display: block;
    width: 72%;
    margin: 0px auto;
    border-radius: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    font-size: 18px;
  }
}
</style>
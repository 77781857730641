import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'
import { Message } from 'element-ui'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      isLogin: true,
      title: 'router_text_2',
    },
    children: [
      {
        path: 'user',
        name: 'user',
        component: resolve => require(['../views/user/user.vue'], resolve),
        meta: {
          // 页面标题title
          title: 'user_title'//登录
        }
      },
      // 数据列表
      {
        path: 'data',
        name: 'data',
        component: resolve => require(['../views/data/data'], resolve),
      },
      // 小说列表
      {
        path: 'booksList',
        name: 'booksList',
        component: resolve => require(['../views/books/booksList'], resolve),
      },
      // 单本小说
      {
        path: 'book/:id',
        name: 'book',
        component: resolve => require(['../views/books/book'], resolve),
        meta: {
          // 页面标题title
          title: 'book_title'//登录
        }
      },
      // 渠道管理
      {
        path: 'channel',
        name: 'channel',
        component: resolve => require(['../views/channel/channel'], resolve),
      },
      // 渠道管理
      {
        path: 'account',
        name: 'account',
        component: resolve => require(['../views/account/account'], resolve),
      },
      // 推广数据列表
      {
        path: 'promoteList',
        name: 'promoteList',
        component: resolve => require(['../views/promote/list'], resolve),
      }
    ]
  },
  //登录
  {
    path: '/login',
    name: 'Login',
    component: resolve => require(['../views/login/Login'], resolve),
    meta: {
      // 页面标题title
      title: 'login_title'//登录
    }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]
//渠道CPS身份可访问 即 cps = 1
const channelRoutes = ['/login','/user','/account','/promoteList']
//内容CPS身份可访问 即 cps = 0
const contentRoutes = ['/login','/user','/data','/booksList','/book/','/channel','/account']
const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//路由拦截
router.beforeEach((to, from, next) => {
  // console.log(to)
  const _this = router.app.$options
  const lang = router.app.$options.i18n.locale //获取语言包标识
  const message = router.app.$options.i18n._vm.messages[lang].message  //语言包
  const title = message[to.meta.title] ? message[to.meta.title] : message.app_name  //对应title

  // 设置标题
  if (to.meta.title) {//如果设置标题，拦截后设置标题
    document.title = title
  }
  // console.log(to.meta.isLogin)
  // 是否检查登录
  if (to.path !== "/login") {
    // 未登录
    if (_this.store.state.userInfo.anyue_token == null || _this.store.state.userInfo.anyue_token == '') {
      next({ path: '/login' });
    }
  }

  if (_this.store.state.userInfo.sign_status == 1) {
    Message.error({
      message: message.thisServiceIsNoLongerAvailableAndTheDataNoLongerUpdated,
      type: 'error'
    })
    if (to.path != '/account' && to.path != '/login') {
      next({ path: '/account' })
    }
  }
  let cps = store.state.userInfo.cps
  let routesNow = []
  if (cps=='1'){
    routesNow = [...channelRoutes]
  }else if (cps=='0'){
    routesNow = [...contentRoutes]
  }
  // 默认跳转数据中心
  if (to.path == "/") {
    if (cps == '1') {
      next({ path: '/promoteList' });
    }else if (cps=='0'){
      next({ path: '/data' });
    }
  }
  // matchingRoute 匹配路由
  // var matchingRoute = routesNow.find(item=>item==to.path)
  var matchingRoute = routesNow.find(item=> {
    if (cps == '0') {
      if (to.path.indexOf('/book/')===0){
        var pathArr = to.path.split('/book/')
        var isBookId = pathArr.length==2 && (pathArr[1]>0)
        if (isBookId){
          return '/book/'
        }else {
          return item == to.path
        }
      }else {
        return item == to.path
      }
    }else if (cps == '1') {
      return item == to.path
    }
  })
  console.log(matchingRoute,'matchingRoute')
  if (!matchingRoute && _this.store.state.userInfo.anyue_token && from.path!='/login'){
    console.log('进来这里，说明非路由文件页')
    if(from.path == "/") {
      console.log('进来这里，说明由/页进入非路由文件页')
      if (cps == '1') {
        next({ path: '/promoteList' });
      }else if (cps=='0'){
        next({ path: '/data' });
      }
    }else {
      console.log('进来这里，说明由路由文件页进入非路由文件页')
      next({
        path: from.path
      })
    }
  }
  next()
})
export default router

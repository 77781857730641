<template>
  <div class="home w-100 h-100">
    <el-container class="w-100 h-100">
      <!-- 头 -->
      <el-header height="60px">
        <home-header @business="business"></home-header>
      </el-header>
      <!-- 中 -->
      <el-container style="height: 100%; padding-top: 60px">
        <!-- 侧边栏 -->
        <el-aside width="200px">
          <home-sidebar></home-sidebar>
        </el-aside>
        <!-- 主体 -->
        <el-main>
          <!-- 二级路由 -->
          <router-view />
        </el-main>
      </el-container>
    </el-container>

    <div class="business">
      <el-dialog title="" :visible.sync="forgotPassword" width="450px">
        <div class="title">{{$t('message.contact')}}</div>
        <div class="content_item" style="margin-top: 40px">
          <span>{{$t('message.name')}}：</span>
          <span>{{$t('message.MrRuan')}}</span>
        </div>
        <div class="content_item">
          <span>{{$t('message.qq')}}：</span>
          <span>47599126</span>
        </div>
        <div class="content_item">
          <span>{{$t('message.E-mail')}}：</span>
          <span>writers@Hinovel.com</span>
        </div>
        <div class="btn button-me" @click="forgotPassword = false">{{$t('message.ok')}}</div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import HomeHeader from "@/components/header/header"; //顶部栏
import HomeSidebar from "@/components/sidebar/sidebar"; //顶部栏

export default {
  name: "Home",
  data() {
    return {
      forgotPassword: false,
    };
  },
  methods: {
    business() {
      this.forgotPassword = true;
    },
  },
  components: {
    // HelloWorld
    HomeHeader,
    HomeSidebar,
  },
};
</script>
<style lang="scss" scoped>
.home {
  .el-main::-webkit-scrollbar {
    // display: none;
  }
  .el-header {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 1200px;
    z-index: 111;
  }
  .el-container {
    background: #f2f4f6;
    // height: 100%;
    // margin-top: 60px;
  }

  .el-main {
    padding-left: 50px;
    padding-top: 40px;
    padding-right: 50px;
  }

  .business {
    .el-dialog__body {
      .title {
        font-size: 24px;
        margin-top: 2px;
        font-weight: 500;
        color: #15161a;
        text-align: center;
      }
      .content_item {
        width: 264px;
        margin: 0 auto;
        margin-top: 18px;
        span:nth-child(1) {
          font-size: 16px;
          font-weight: 400;
          color: #656565;
        }
        span:nth-child(2) {
          font-size: 16px;
          font-weight: 500;
          color: #262626;
          margin-left: 8px;
        }
      }
      .btn {
        cursor: pointer;
        width: 200px;
        height: 46px;
        text-align: center;
        line-height: 46px;
        margin: 0 auto;
        margin-top: 46px;
        color: #fff;
        font-size: 16px;
        color: #ffffff;
        background: #3d76ff;
        border-radius: 8px;
      }
    }
  }
}
</style>
<style lang="scss">
.business {
  .el-dialog {
    border-radius: 10px !important;
    .el-dialog__header {
      padding: 0px 10px 10px;
      .el-icon {
        font-size: 24px;
      }
    }
    .el-dialog__headerbtn {
      display: none;
    }
  }
}
</style>
const en = {
  message: {
    // 项目名称
    'app_name': 'HiCPS',
    // 用户信息页面title
    'user_title': 'user',
    // 登陆页面title
    'login_title': 'login',
    // 书本页面title
    'book_title': 'book',
    // 登录账号
    'uiserName': 'Username',
    // 请输入登录账号
    'pleaseEnterUsername': 'Please enter Username',
    // 请输入登录密码
    'pleaseEnterPassword': 'Please enter Password',
    // 登录密码
    'password': 'Password',
    // 登陆
    'login': 'Login',
    // 忘记密码
    'forgotPassword': 'Forgot Password',
    // 确定
    'ok': 'OK',
    // 数据中心（备注：翻译可否用一个单词能够表达)
    'statistics': 'Statistics',
    // 小说管理（备注：翻译可否用一个单词能够表达)
    'novels': 'Novels',
    // 推广管理（备注：翻译可否用一个单词能够表达)
    'Link': 'Link',
    // 结算管理（备注：翻译可否用一个单词能够表达)
    'payment': 'Payment',
    // 计算记录
    'history': 'History',
    // 联系商务
    'contact': 'Contact',
    // 退出
    'exit': 'Exit',
    // 请输入小说名称
    'searchNovels': 'Search novels',
    // 搜索
    'search': 'Search',
    // 热度排行
    'trending': 'Trending',
    // 小说名称
    'novels': 'Novels',
    // 创建书城推广链接（备注：翻译简短些)
    'createLinkForTheBookStore': 'Create link for the book store',
    // 分类
    'category': 'Category',
    // 已推次数
    'createdLinks': 'Created links',
    // 推广
    'link': 'Link',
    // 链接ID
    'linkId': 'Link ID',
    // 创建推广链接
    'createAReferralLink': 'Create a referral link',
    // 推广链接
    'referralLink': 'Referral link',
    // 书城
    'bookStore': 'Book Store',
    // 生成推广链接
    'create': 'Create',
    // 已完结
    'ended': 'Ended',
    // 连载中
    'running': 'Running',
    // 章
    'chapter': 'Chapter',
    // 字
    'words': 'words',
    // 推广该书
    'promote': 'Promote',
    // 收费
    'price': 'Price',
    // 免费
    'free': 'Free',
    // 复制
    'copy': 'Copy',
    // 复制成
    'copied': 'Copied',
    // 链接ID
    'referralLinkID': 'Referral link ID',
    // 点击
    'clicks': 'Clicks',
    // 充值次数
    'top-ups': 'Top-ups',
    // 充值金额（$）
    'amount': 'Amount ($)',
    // 佣金（$）
    'commission': 'Commission ($)',
    // 日期
    'date': 'Date',
    // 新增用户
    'newUsers': 'New users',
    // 用户信息
    'user': 'User',
    // 合作信息
    'details': 'Details',
    // 收款信息
    'paymentPreferences': 'Payment Preferences',
    // 银行收款
    'bankTransfer': 'Bank Transfer',
    // 支付宝收款
    'Alipay': 'Alipay',
    // 收款方式
    'paymentMethod': 'Payment method',
    // 收款人
    'recipient': 'Recipient',
    // 银行全称
    'bank': 'Bank',
    // 例：**银行**支行
    'eg': 'e.g. *** Bank *** Branch',
    // 取消
    'cancel': 'Cancel',
    // 提交审核
    'submit': 'Submit',
    // 审核中
    'processing': 'Processing',
    // 重新编辑
    'edit': 'Edit',
    // 审核失败
    'failure': 'Failure:',
    // 银行卡
    'debitCard': 'Debit Card',
    // 户名
    'recipient': 'Recipient',
    // 卡号
    'cardNumber': 'Card number',
    // 支付宝号
    'AlipayAccount': 'Alipay account',
    // 渠道名称
    'platform': 'Platform',
    // 账号
    'account': 'Account',
    // 分成比例
    'commission': 'Commission',
    // 修改密码
    'resetYourPassword': 'Reset your password',
    // 原密码
    'currentPassword': 'Current password',
    // 新密码
    'newPassword': 'New password',
    // 确认密码
    'confirmPassword': 'Confirm password',
    // 保存
    'done': 'Done',
    // 账户余额（$）
    'accountBalance': 'Account balance ($)',
    // 已打款
    'paid': 'Paid',
    // 已打款
    'paid ($)': 'Paid ($)',
    // 结算记录
    'payouts': 'Payouts',
    // 序号
    'number': 'Number',
    // 结算时间跨度
    'date1': 'Date',
    // 结算佣金（$）
    'commission ($)': 'Commission ($)',
    // 状态
    'status': 'Status',
    // 备注
    'note': 'Note',
    // 打款失败
    'failure': 'Failure',
    // 海量小说，随心畅读
    'ALibraryOfNovelsAtYourFingertips': 'A library of novels at your  fingertips',
    // 没有更多数据
    'noMoreData': 'No more data',
    // 继续
    'continue': 'Continue',
    // 请输入原密码
    'currentPassword': 'Current password',
    // 密码最短输入8位
    'APasswordShouldBeAtLeast8Characters': 'password should be at least 8 characters',
    // 请输入新密码
    'pleaseEnterNewPassword': 'please enter new password',
    // 请输入确认密码
    'confirmNewPassword': 'Confirm new password',
    // 新密码与确认密码不一致
    'pleaseMakeSureYourPasswordsMatch': 'Please make sure your passwords match!',
    // 请输入户名
    'recipent': 'Please enter the full name of the recipien',
    // 请输入银行卡号
    'cardNumber': 'please enter the card number of the recipent',
    // 请输入银行卡全称
    'pleaseEnterTheFullNameOfTheBank': 'Please enter the full name of the bank',
    // 联系人
    'name': 'Name',
    // 手机号码
    'phone': 'Phone',
    // 邮箱
    'E-mail': 'E-mail',
    // 已推次数（备注：翻译简短些)
    // 'linksCreated': 'Links created',
    // 请输入收款人
    'recipient': 'Recipient',
    // 请输入支付宝号
    'AlipayAccount': 'Alipay account',
    // 推广链接生成成功
    'linkCreated': 'Link created',
    // 复制下发推广链接，后续您可以在【后台-小说管理-推广链接】中找到它并查看它的统计数据
    'CopyLink': 'Copy and share your referral link and view referral data from Dashboard > Novels > Referral Link',
    // 未找到该用户,请重新输入
    'userNotFound': 'User not found, please retry',
    // 密码不正确,请重新输入
    'passwordIncorrect': ' Password incorrect, please retry',
    // 登录失败，请联系管理员
    'loginFailed': 'Login failed, please contact admin',
    //阮先生
    'MrRuan': 'Mr. Ruan',
    // 密码修改成功
    'passwordUpdated': 'Password updated',
    // 提交成功
    'successfullySubmitted': 'Successfully submitted',
    // 该书已下架
    'thisItemIsNoLongerAvailable': 'This item is no longer available.',
    // 渠道商已解约，数据不再更新
    'thisServiceIsNoLongerAvailableAndTheDataNoLongerUpdated': 'This service is no longer available and the data no longer updated',
    'qq': 'QQ',
    // 书城
    'theBookStore': 'The book store',
    // 当前收款信息
    'currentPaymentPreferences': 'Current payment preferences',
    // 推广数据
    'promotion': 'Promotion Statistics',
    'promotePlaceholder': 'Please select a date range',
    'promoteDate': 'Date',
    'promoteAddUserNum': 'Number of new users',
    'promoteTotalRechargeAmount': 'Top-up amount ($)',
    'promotePassageFee': 'Channel fee',
    'promoteDividableAmount': 'Amount eligible for commission ($)',
    'promoteShareRatio': 'Commission rate',
    'promoteActualShareAmount': 'Commission ($)',
    'yesterday': 'Yesterday',
    'today': 'Today',
    'sevenDay': 'Past 7 Days',
    'fifteenDay': 'Past 15 Days',
    'thirtyDay': 'Past 30 Days',
    'ninetyDay': '过去90天',
    'customDay': 'customize',
    'startDate': 'Start Date',
    'endDate': 'End Date',
  }
}
export default en;

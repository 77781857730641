<template>
  <div class="sidebar">
    <template  v-if="cps=='1'">
      <div class="sidebar_item flexCenter-ai-center" :class=" path == '/promoteList' ? 'action' : ''" @click="nextPage('/promoteList')">
        <img src="@/assets/images/data.1.png" alt="" v-if="path == '/promoteList'">
        <img src="@/assets/images/data.png" alt="" v-else>
        <p>{{$t('message.promotion')}}</p>
      </div>
      <div class="sidebar_item flexCenter-ai-center" :class=" path == '/account' ? 'action' : ''" @click="nextPage('/account')">
        <img src="@/assets/images/money.1.png" alt="" v-if="path == '/account'">
        <img src="@/assets/images/money.png" alt="" v-else>
        <p>{{$t('message.payment')}}</p>
      </div>
    </template >
    <template  v-else>
      <div class="sidebar_item flexCenter-ai-center" :class=" path == '/data' ? 'action' : ''" @click="nextPage('/data')">
        <img src="@/assets/images/data.1.png" alt="" v-if=" path == '/data'">
        <img src="@/assets/images/data.png" alt=""  v-else >
        <p>{{$t('message.statistics')}}</p>
      </div>
      <div class="sidebar_item flexCenter-ai-center" :class=" path == '/booksList' || path == '/book' ? 'action' : ''"  @click="nextPage('/booksList')">
        <img src="@/assets/images/book.1.png" alt="" v-if="path == '/booksList' || path == '/book'">
        <img src="@/assets/images/book.png" alt="" v-else>
        <p>{{$t('message.novels')}}</p>
      </div>
      <div class="sidebar_item flexCenter-ai-center" :class=" path == '/channel' ? 'action' : ''" @click="nextPage('/channel')">
        <img src="@/assets/images/horn.1.png" alt="" v-if="path == '/channel'">
        <img src="@/assets/images/horn.png" alt="" v-else>
        <p>{{$t('message.link')}}</p>
      </div>
      <div class="sidebar_item flexCenter-ai-center" :class=" path == '/account' ? 'action' : ''" @click="nextPage('/account')">
        <img src="@/assets/images/money.1.png" alt="" v-if="path == '/account'">
        <img src="@/assets/images/money.png" alt="" v-else>
        <p>{{$t('message.payment')}}</p>
      </div>
    </template >
  </div>
</template>
<script>
import store from '../../store/index'
export default {
  name: 'sidebar',
  data () {
    return {
      path: '/user',
      cps:''
    }
  },
  created () {
    this.path = this.$route.path
    let cps = store.state.userInfo.cps
    this.cps = cps
  },
  methods: {
    nextPage(path) {
      this.$router.push(
        {
          path
        }
      )
    },
  },
  watch:{
    $route(to,from){
      this.path = to.path
    }
  },
}
</script>
<style lang="scss" scoped>
.sidebar {
  // height: calc(100% - 60px);
  height: 100%;
  background-color: #fff;
  padding-top: 20px;
  // position: fixed;
  // top: 60px;
  // left: 0;
  // width: 200px;
  .sidebar_item {
    height: 60px;
    cursor:pointer;
    &.action{
      background-color: #F2F6FF;
      // border-left: 4px solid #3D76FF;
      p {
        color: #2F6AFA;
      }
    }
    >img {
      width: 30px;
      margin-left: 42px;
    }
    p {
      color: #57595F;
      font-size: 16px;
      font-weight: 400;
      margin-left: 12px;
    }
  }
  .sidebar_item:nth-child(1) {
  }
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/scss/color.scss';
import warning from './components/warning' //注册全局提示框
Vue.component('warning', warning)


import VueI18n from "vue-i18n";
Vue.use(VueI18n); // 通过插件的形式挂载
// 国际化element
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import enLocale from "element-ui/lib/locale/lang/en";
// 自定义
import zhLang from "./i18n/zh";
import enLang from "./i18n/en";

Vue.use(ElementUI);
Vue.use(VueI18n); // 通过插件的形式挂载
const i18n = new VueI18n({
  locale: "en", // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    zh: { ...zhLang, ...zhLocale }, // 中文语言包
    en: { ...enLang, ...enLocale }, // 英文语言包
  },
});
locale.i18n((key, value) => i18n.t(key, value))
// Vue.use(ElementUI, {
//   i18n: (key, value) => {
//     console.log(key);
//     console.log(value);
//     return i18n.t(key, value);
//   }
// });

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

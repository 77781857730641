// import zhLocale from 'element-ui/lib/locale/lang/zh-CN' // element组件自带文本
const zh = {
  message: { // 页面上我们的文本
    'app_name': 'HiCPS',
    'dw': '(US$)',
    // 'dw': '',
    'dw_text': 'US$',
    // 'dw_text': '',
    'text_1': '登录',
    'text_2': '登录账号',
    'text_3': '登录密码',
    'text_4': '请输入登录账号',
    'text_5': '请输入登录密码',
    'text_6': '长度在 6 到 16 个字符',
    'text_7': '登录',
    'text_8': '退出登录',
    'text_9': '登录成功',
    'text_10': '请输入正确的账号密码',
    'text_11': '数据中心',
    'text_12': '结算中心',
    'text_13': '昨天数据',
    'text_14': '数据更新至',
    'text_15': '新增用户数',
    'text_16': '充值总金额',
    'text_17': '分成总收入',
    'text_18': '请选择日期',
    'text_19': '最近七天',
    'text_20': '最近三十天',
    'text_21': '日期',
    'text_22': '新增用户数',
    'text_23': '没有更多数据',
    'text_24': '财务',
    'text_25': '选择月份',
    'text_26': '已付款',
    'text_27': '待结算',
    'text_28': '分成总收入',
    'text_29': '状态',
    'text_30': '您的收入',
    'text_31': '如果总额不低于',
    'text_32': '50（您的付款最低限额），我们会按月付款',
    'text_33': '你已达到起付金额 ',
    'text_34': '起付金额',
    'text_35': '上次付款日期',
    'text_36': '金额为',
    'text_37':'您的收入',
    'router_text_1': '渠道商平台-登录',
    'router_text_2': '渠道商平台-数据中心',
    'router_text_3': '渠道商平台-结算中心',
    // 推广数据
    'promotion': '推广数据',
    'promotePlaceholder': '请选择日期',
    'promoteDate': '日期',
    'promoteAddUserNum': '新增用户数',
    'promoteTotalRechargeAmount': '总充值金额$',
    'promotePassageFee': '通道费',
    'promoteDividableAmount': '可分成金额$',
    'promoteShareRatio': '分成比例',
    'promoteActualShareAmount': '实际分成金额$',
    'yesterday': '昨天',
    'today': '今天',
    'sevenDay': '过去7天',
    'fifteenDay': '过去15天',
    'thirtyDay': '过去30天',
    'ninetyDay': '过去90天',
    'customDay': '自定义',
    'startDate': '开始日期',
    'endDate': '结束日期',

  },
  // ...zhLocale
}

export default zh

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      //用户token
      // user_token: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).anyue_token : '',
      // lang	string	语言 例 ： ‘en’
      // lang_id	string	语言ID 例 ：英语： 1， 其他语言待定
      // anyue_token	string	身份校验盐值
      // distributor_name	string	渠道商名称
      // separate_rate	string	分成比例
      // login_name	string	登录账号
      // login_password_default	string	密码掩码， 默认 “**“
    userInfo: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
    //语言(字符串)
    language: localStorage.getItem('language') ? localStorage.getItem('language') : "zh",
  },
  mutations: {
    setUserInfo(state, val) {
      state.userInfo = val
    },
    setLanguage(state, val) {
      state.language = val
    }
  },
  actions: {
  },
  modules: {
  }
})
